import React, { useState } from 'react'
import { Link } from 'react-router-dom';
 import Logo from './../../img/guru1.jpeg';
// import Logo1 from './../../img/logo1.webp';
// import Logo2 from './../../img/logo2.webp';
// import Logo3 from './../../img/logo3.webp';
const Header = () => {
  const [menuActive, setMenuActive] = useState("");

const closeMenu = () => {
  setMenuActive("");
};

const openMenu = () => {
  setMenuActive("active");
};
  return (
    <div>
      <header>
            <div className="container">
                 <div className="row">
                     <div className="col-lg-2 col-sm-2 col-6">
                         <div className="logo">
                         <Link to="/" onClick={closeMenu}><img src={Logo} className="img-fluid" alt='' /></Link>
                         </div>
                     </div>
                     <div className='col-lg-9 offset-lg-1 col-sm-10  col-6'>
                         <div className="openBtn"><button onClick={openMenu}><i className="fa fa-bars"></i></button></div>
                        <div className={"menu " + menuActive}>
                          <button onClick={closeMenu}><i className="fa fa-close"></i></button>
                          <ul>
                            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
                            <li><Link to="about-us" onClick={closeMenu}>About Us</Link></li>
                            <li><Link to="services" onClick={closeMenu}>Our Services</Link></li>
                            {/* <li><Link to="courses" onClick={closeMenu}>Courses</Link></li> */}
                            <li><Link to="tutors" onClick={closeMenu}>Tutors</Link></li>
                            <li><Link to="gallery" onClick={closeMenu}>Gallery</Link></li>
                            <li><Link to="contact-us" onClick={closeMenu}>Contact us</Link></li>
                            <li><Link to="tel:09999953835" className='downloadbtn' onClick={closeMenu}><i className="fa fa-phone"></i> Call Us (+91) 9999953835</Link></li>
                          </ul>
                        </div>
                     </div>
                    
                 </div>
            </div>
        </header>
    </div>
  )
}

export default Header
