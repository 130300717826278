import React from 'react'
import Banner from '../../Components/Banner/Banner';
import Breadcum from '../../Components/Breadcum/Breadcum';
import persnal from "./../../img/personal_teacher.svg"
import service_2 from "./../../img/service_2.svg"
import service_3 from "./../../img/service_3.svg"
import service_4 from "./../../img/service_4.svg"
import service_5 from "./../../img/service_5.svg"
import service_6 from "./../../img/service_6.svg"


const Disclaimer = () => {
  return (
    <div>
      <Banner bl="S" l1="Se" l2="rv" l3="ic" l4="es" />
      <div className='otherpagebanner'>
        <Breadcum path='/' name="Home" pagename="Our Services" />
      </div>
      <div className="ourService">
        <div className="container">
          <div className="section-title mb-4 headline text-left "  >
            
            <h2><span>  Disclaimer for Guru Classes</span></h2>
          </div>
        
<strong>
General Information:</strong>
<p>
The information provided on the Guru Classes website is for general informational purposes only. We strive to ensure the accuracy and reliability of the information presented, but we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
</p>
<strong>
Educational Services:
</strong>
<p>
Our tutoring services, including home tuition, online tuition, and small group tuition, are designed to provide educational assistance and support. We do not guarantee specific academic outcomes or grades. Academic progress is dependent on various factors, including the effort and commitment of the student.
</p>
<strong>
Tutor Qualifications:
</strong>
<p>
While we take utmost care in selecting and training our tutors, we cannot guarantee the individual outcomes of tutoring sessions. The effectiveness of tutoring may vary depending on the student's learning style, prior knowledge, and engagement in the learning process.
</p>
<strong>
External Links:
</strong>
<p>
Our website may contain links to external websites that are not under our control. These links are provided for your convenience and to offer additional resources. We do not endorse or accept responsibility for the content, policies, or practices of any linked website. You should review the terms and policies of external websites before using their services or accessing their content.
</p>
<strong>
Testimonials:
</strong>
<p>
Testimonials and reviews on our website represent the experiences and opinions of individual clients. Results may vary, and we do not guarantee that other individuals will achieve the same outcomes.
</p>
<strong>
Privacy and Data Security:
</strong>
<p>
We are committed to protecting your privacy and the security of your personal information. Please review our Privacy Policy for detailed information on how we collect, use, and safeguard your data.
</p>
<strong>
Changes to Information:
</strong>
<p>
The content of this website is subject to change without notice. We may update information, services, and pricing as needed. It is your responsibility to check our website periodically for any updates or changes.
</p>
<strong>
Legal Jurisdiction:
</strong>
<p>
This website is governed by the laws of District and Session court Gurugram, Any disputes arising from the use of this website are subject to the exclusive jurisdiction of the courts in District and Session court Gurugram.
</p>
By using our website and our tutoring services, you agree to accept these terms and conditions in their entirety. If you disagree with any part of this disclaimer, please refrain from using our website and services.
<p>
If you have any questions or concerns about this disclaimer, please contact us at guruclasses.in@gmail.com
</p>
<p>
Last updated: 24-09-2023
</p>
        </div>
      </div>
      
    </div>
  )
}

export default Disclaimer
