import React, {useEffect, useState} from 'react'
import OwlCarousel from 'react-owl-carousel';

import ramimg from "./../../img/rampic.webp"
import gopalkumar from "./../../img/gopalkumar-guru-classes-co-in.jpg"
import manish from "./../../img/manish-guru-class-co-in.jpg"
import vinodkumar from "./../../img/vinodkumar-guru-classes-co-in.jpg"

const Tutors = () => {
    const [options, setOptions] = useState({
        margin: 15,
        loop: true,
        autoplay: true,
        responsiveClass: true,
        nav: false,
        dots: true,
        responsive: {
          0: {
            items: 1.5,
          },
          600: {
            items: 4,
          },
        },
      });
    
      useEffect(() => {
        setOptions((prevOptions) => {
          return {
            ...prevOptions,
            responsive: {
              0: {
                items: 1.5,
              },
              600: {
                items: 4,
              },
            },
          };
        });
      }, []);
  return (
    <div>
    <section id="why-choose" className="tutors backgroud-style">
          <div className="container">
              <div className="section-title mb-4 headline text-center ">
                  <span className="subtitle text-uppercase"><span title='Guru classes.'>OUR</span> EXPERTS</span>
                  <h2 >Meet Our  <span> <span title='Guru classes.'>Tutors.</span></span></h2>
              </div>
              <div className='row justify-content-sm-center'>
                <div className='col-sm-7'>
                     <p className='tutorstxt'>Our tutors will visit your home, school or work place to provide one-to-one tuition
at a time and day convenient to you. We have sessions available 7 days a week with</p>
                </div>
              </div>
              <OwlCarousel className='owl-theme' {...options}>
            <div className='tutorsbox'>
                <div className='tutorsImg'>
                     <img src={ramimg} alt="" />
                </div>
                <div className='tutorsname'>
                    <h3>Rameshwar (Ram sir)</h3>
                    <p>Maths Teacher</p>
                </div>
            </div>
            <div  className='tutorsbox'>
                <div className='tutorsImg'>
                     <img src={gopalkumar} alt="" />
                </div>
                <div className='tutorsname'>
                    <h3>Gopal Kumar</h3>
                    <p>Computer Teacher</p>
                </div>
            </div>
            <div  className='tutorsbox'>
                <div className='tutorsImg'>
                     <img src={manish} alt="" />
                </div>
                <div className='tutorsname'>
                    <h3>Manish Jha</h3>
                    <p>Science Teacher</p>
                </div>
            </div>
            <div  className='tutorsbox'>
                <div className='tutorsImg'>
                     <img src={vinodkumar} alt="" />
                </div>
                <div className='tutorsname'>
                    <h3>Vinod Kumar</h3>
                    <p>Accounts Teacher</p>
                </div>
            </div>
        </OwlCarousel>
          </div>
      </section>
  </div>
  )
}

export default Tutors
