import React from 'react'

const Banner = (props) => {
  return (
    <div data-target="1" class="slide slide--1">
          <div class="slide__darkbg slide--1__darkbg"></div>
          <div class="slide__text-wrapper slide--1__text-wrapper">
              <div class="slide__letter slide--1__letter">{props.bl}</div>
              <div class="slide__text slide__text--1">{props.l1}</div>
              <div class="slide__text slide__text--2">{props.l2}</div>
              <div class="slide__text slide__text--3">{props.l3}</div>
              <div class="slide__text slide__text--4">{props.l4}</div>
              {/* <div class="slide__text slide__text--5">m</div> */}
          </div>
      </div>
  )
}

export default Banner
