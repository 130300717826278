import React from 'react'
import A from "./../Link/A"
const Breadcum = (props) => {
  const {path, name, pagename} = props;
  return (
    <div>
       <div className="breadcum"><A to={path} name={name} /> / {pagename}</div>
    </div>
  )
}

export default Breadcum