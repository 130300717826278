import React from 'react';
import Banner from '../../Components/Banner/Banner';

// import Lottie from "lottie-react";
// import AboutJson from "./../../img/Json/about.json";
import Breadcum from './../../Components/Breadcum/Breadcum';
// import A from '../../Components/Link/A';
const About = () => {
  
  return (
    <div>
      <Banner bl="A" l1="Ab" l2="ou" l3="t&nbsp;" l4="us" />
      {/* <div class="shape">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 280">
				<path fill="#ffffff" fill-opacity="1">
					<animate attributeName="d" dur="20000ms" repeatCount="indefinite" values="M0,160L48,181.3C96,203,192,245,288,261.3C384,277,480,267,576,234.7C672,203,768,149,864,117.3C960,85,1056,75,1152,90.7C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z; M0,160L48,181.3C96,203,192,245,288,234.7C384,224,480,160,576,133.3C672,107,768,117,864,138.7C960,160,1056,192,1152,197.3C1248,203,1344,181,1392,170.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;												 M0,64L48,74.7C96,85,192,107,288,133.3C384,160,480,192,576,170.7C672,149,768,75,864,80C960,85,1056,171,1152,181.3C1248,192,1344,128,1392,96L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;
													 M0,160L48,181.3C96,203,192,245,288,261.3C384,277,480,267,576,234.7C672,203,768,149,864,117.3C960,85,1056,75,1152,90.7C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;"></animate>
					
				</path>
				
			</svg>
		</div> */}
<div className='otherpagebanner'>
    <Breadcum path='/' name="Home" pagename="About Us" />
</div>       
<div className="aboutContent">
    <div className="container">  
        <div className="otherPageContent">
        <h2><strong title='ABOUT GURU CLASSES'>ABOUT GURU CLASSES</strong></h2>
        <p className="text-justify"><strong title='Guru classes'>Guru Classes,</strong> a premier home tuition service provider for CBSE, ICSE, IGCSE, and IB curriculum. We believe that every student has the potential to succeed, regardless of their background, learning style or availability. So we also offer online tuition & small group tuition along with home tuition services to meet the needs of all learners.</p>
<p  className="text-justify">
Our tutors are highly qualified and experienced, They use a variety of teaching methods and techniques to create a personalized learning experience for each student. We provide experts for a wide range of subjects.</p>
<h2 className='text-uppercase'><strong>Subjects We Cover:</strong></h2>
<p  className="text-justify">We offer home tuition services for a wide range of subjects:</p>
<ul>
  <li>Mathematics</li>
  <li>Science (Physics, Chemistry, Biology)</li>
  <li>English</li>
  <li>Social Studies</li>
  <li>Languages (Hindi, French, Spanish, etc.)</li>
  <li>Computer Science</li>
  <li>Economics</li>
  <li>Accountancy</li>
  <li>Business Studies</li>
</ul>
</div>
   </div>
</div>  
    </div>
  )
}

export default About