import React from 'react'
import { Link } from "react-router-dom";

const A = (props) => {
  const {name,to} = props;
  return (
    <Link to={to}>{name}</Link>
  )
}

export default A