import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    scroll.scrollToTop({
      duration: 500, // Set the duration of the smooth scroll animation
      smooth: true, // Enable smooth scrolling
    });
  }, [location]);

  return null;
};

export default ScrollToTop;