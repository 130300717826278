import React, {useEffect, useState} from 'react'
import Lottie from "lottie-react";
import animationData from './../../img/Json/Why.json';
import OwlCarousel from 'react-owl-carousel';
const WhyChoose = () => {
		const [options, setOptions] = useState({
			margin: 15,
			loop: true,
			autoplay: true,
			responsiveClass: true,
			nav: false,
			dots: true,
			responsive: {
			  0: {
				items: 1.5,
			  },
			  600: {
				items: 3,
			  },
			},
		  });
		
		  useEffect(() => {
			setOptions((prevOptions) => {
			  return {
				...prevOptions,
				responsive: {
				  0: {
					items: 1.5,
				  },
				  600: {
					items: 3,
				  },
				},
			  };
			});
		  }, []);
  return (
    <div>
      <section id="why-choose" className="why-choose-section backgroud-style">
			<div className="container">
				<div className="section-title mb-4 headline text-center ">
					<span className="subtitle text-uppercase"><span title='Guru classes.'>CLASSES</span> ADVANTAGES</span>
					<h2 >Reason Why Choose <span> <span title='Guru classes.'>Guru classes.</span></span></h2>
				</div>
				<OwlCarousel className='owl-theme' {...options}>
                    <div className='whybox borcolor_1'>
					    <div className='whychoose_top bgcolor_1'>
						<div className='whychoose_icon'><i className='fa fa-user'></i></div>
						<h3>Expert Tutors:</h3>
						</div>
						<div className='whychoose_bottom'>
							<p>Our tutors are highly qualified and experienced in their respective subjects. They have a deep understanding of the CBSE, ICSE, IGCSE, and IB curriculum, ensuring that they can provide the best guidance and support to your child.</p>
						</div>
					</div>
					<div className='whybox borcolor_2'>
					    <div className='whychoose_top bgcolor_2'>
						<div className='whychoose_icon'><i className='fa fa-home'></i></div>
						<h3>Customized Learning</h3>
						</div>
						<div className='whychoose_bottom'>
							<p>We believe that every child has unique learning needs. Our tutors tailor their teaching methods to suit your child's individual learning style, helping them grasp concepts more effectively and build a strong foundation in each subject.</p>
						</div>
					</div>
					<div className='whybox borcolor_3'>
					    <div className='whychoose_top bgcolor_3'>
						<div className='whychoose_icon'><i className='fa fa-bar-chart'></i></div>
						<h3>Comprehensive Curriculum</h3>
						</div>
						<div className='whychoose_bottom'>
							<p>Whether your child is studying CBSE, ICSE, IGCSE, or IB, our tutors are well-versed in the respective syllabus. They cover all key topics and ensure that your child is well-prepared for examinations and assessments.</p>
						</div>
					</div>
					<div className='whybox borcolor_4'>
					    <div className='whychoose_top bgcolor_4'>
						<div className='whychoose_icon'><i className='fa fa-book'></i></div>
						<h3>One-on-One Attention</h3>
						</div>
						<div className='whychoose_bottom'>
							<p>Unlike crowded classrooms, our home tuition service offers personalized attention to your child. This allows our tutors to identify and address any learning gaps, boost confidence, and develop an interest for learning.</p>
						</div>
					</div>
					<div className='whybox borcolor_5'>
					    <div className='whychoose_top bgcolor_5'>
						<div className='whychoose_icon'><i className='fa fa-users'></i></div>
						<h3>Flexible Scheduling</h3>
						</div>
						<div className='whychoose_bottom'>
							<p>We understand that your child may have a busy schedule with other commitments. That's why we offer flexible scheduling options, allowing you to choose convenient time slots for tuition sessions that fit into your child's routine.</p>
						</div>
					</div>
					<div className='whybox borcolor_6'>
					    <div className='whychoose_top bgcolor_6'>
						<div className='whychoose_icon'><i className='fa fa-eye'></i></div>
						<h3>Exam Preparation and Test-Taking Strategies</h3>
						</div>
						<div className='whychoose_bottom'>
							<p>With our expertise in various curriculum, we provide specialized exam preparation and test-taking strategies to help students perform at their best during assessments. Our tutors focus on building essential skills, such as time management, problem-solving, and critical thinking, to ensure success in examinations.</p>
						</div>
					</div>
					<div className='whybox borcolor_6'>
					    <div className='whychoose_top bgcolor_6'>
						<div className='whychoose_icon'><i className='fa fa-eye'></i></div>
						<h3>Regular Progress Updates</h3>
						</div>
						<div className='whychoose_bottom'>
							<p>We believe in maintaining transparent communication with parents. Our tutors provide regular progress updates, highlighting your child's strengths and areas for improvement. This helps you stay informed about their academic journey and actively participate in their learning process.</p>
						</div>
					</div>
					<div className='whybox borcolor_6'>
					    <div className='whychoose_top bgcolor_6'>
						<div className='whychoose_icon'><i className='fa fa-eye'></i></div>
						<h3>Competitive Pricing</h3>
						</div>
						<div className='whychoose_bottom'>
							<p>We offer competitive pricing packages that provide excellent value for money. We believe that quality education should be accessible to all, and our pricing structure reflects our commitment to making home tuition services affordable.</p>
						</div>
					</div>
				</OwlCarousel>
			</div>
		</section>
    </div>
  )
}

export default WhyChoose
