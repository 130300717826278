import React, { useEffect, useState } from 'react';

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getGallery = async () => {
    try {
      const response = await fetch("");

      if (!response.ok) {
        throw new Error('Failed to fetch gallery data');
      }

      const data = await response.json();
      setGallery(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getGallery();
  }, []);

  return (
    <div className='otherpage'>
      <div className='container'>
        <div className="section-title mb-4 headline text-center">
          <span className="subtitle text-uppercase">Guru Classes Gallery</span>
          <h2><span>Gallery</span></h2>
        </div>
      </div>
      <div className='gallerysection'>
        <div className='container'>
          <div className='row'>
            {isLoading ? (
              <>
                <div className='col-sm-4'><div className="animated-background"></div></div>
                <div className='col-sm-4'><div className="animated-background"></div></div>
                <div className='col-sm-4'><div className="animated-background"></div></div>
              </>
            ) : (
              gallery.map((ele, index) => (
                <div key={index} className='col-sm-4'>
                  <div className='gallerybox'>
                    <img src={ele.image.url} alt={ele.image.alt} title={ele.image.alt} />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;