import React from 'react'
import Lottie from "lottie-react";
import ContactJson from "./../../img/Json/contact.json";
import Breadcum from '../../Components/Breadcum/Breadcum'
import A from '../../Components/Link/A';
const Contact = () => {
  return (
    <div>
      <div className="otherpage">
    <div className="container">
        <h1 className="title--h1 text-center"><span>Get</span> In Touch</h1>
        <Breadcum path='/' name="Home" pagename="Contact Us" />
        <div className="contactimg" id="contactimg">
        <Lottie animationData={ContactJson} loop={true} />
        </div>
   </div>
   <div className="contact_section">
       <div className="container">
           <div className="row">
               <div className="col-sm-8">
                   <div className="contentForm">
                       <div className="fill_it">Fill The Form</div>
                       <div className="contentFormArea">
                        <form method="" id="contactForm">
                           <div className="rowsection">
                               <div className="row">
                                   <div className="col-sm-6">
                                       <label>Name</label>
                                       <input type="text" name="name" />
                                   </div>
                                   <div className="col-sm-6">
                                       <label>Email</label>
                                       <input type="email" name="email" />
                                   </div>
                               </div>
                           </div>
                           <div className="rowsection">
                               <div className="row">
                                   <div className="col-sm-12">
                                       <label>Subject</label>
                                       <input type="subject" name="subject" />
                                   </div>
                               </div>
                           </div>
                           <div className="rowsection">
                               <div className="row">
                                   <div className="col-sm-12">
                                       <label>Message</label>
                                       <textarea name="message" rows="5"></textarea>
                                   </div>
                               </div>
                           </div>
                           <div className="rowsection">
                               <div className="row">
                                   <div className="col-sm-12">
                                       <button type="submit"id="btn-submit" name="contactForm">Submit Request</button>
                                   </div>
                               </div>
                           </div>
                           <div id="error"></div>
                           </form>
                       </div>
                   </div>
               </div>
               <div className="col-sm-4">
                   <div className="contentForm">
                       <div className="fill_it">Reach Us</div>
                       <div className="contentFormArea">
                           <h2>Get In Touch</h2>
                           <p>We'll Respond in couple of time</p>
                           <ul>
                          <li><span><i className="fa fa-home"></i></span>H.No. 391, Hada Gali, Wazirabad, Sector 52, Gurugram, Haryana, India 122003</li>
                          <li><span><i className="fa fa-phone" ></i></span><A to="tel:919999953835" name="(+91) 9999953835" /></li>
                          <li><span><i className="fa fa-envelope-o" ></i></span> <A to="mailto:info@guruclasses.co.in" name="info@guruclasses.co.in" /></li>
                          <li><span><i className="fa fa-envelope-o" ></i></span> <A to="mailto:rameshwar92@gmail.com" name="rameshwar92@gmail.com" /></li>
                        </ul>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
   <div>
     <div className='container pt-3 pb-3'><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.5862706317866!2d77.0832357!3d28.431738300000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19003b7a869f%3A0x7e2a486d8c6a89ee!2sGuru%20Classes!5e0!3m2!1sen!2sin!4v1708191010468!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
   </div>
</div>     

    </div>
  )
}

export default Contact