import React from 'react'
import A from '../Link/A';
import Logo from './../../img/logoHigh.jpg'; 


const Footer = () => {
  return (
    <div>
      <footer>
   	  <div className="container">
   	  	<div className="row">
   	  	 <div className="col-sm-4">
   	  	 	 <div className="footerDes">
   	  	 	 	<div className="footerlogo">
   	  	 	 		<img src={Logo} className="img-fluid" alt='' />
   	  	 	 	</div>
   	  	 	 	<div className="footertxt">
   	  	 	 		<p>At Guru Classes, we strive to provide the best home tuition services to help your child reach their full potential. Whether you are looking for one-on-one tuition, online tuition, or small group tuition, we have a perfect solution for you. Our team will work with you to find the best tuition service for your child's individual needs. To learn more about our offerings or to schedule a free consultation, please visit our website or contact us directly. Our friendly team is ready to assist you and answer any queries you may have.</p>
   	  	 	 	</div>
   	  	 	 </div>
   	  	 </div>
   	  	 <div className="col-sm-7 offset-sm-1">
   	  	 	<div className="row">
   	  	 		<div className="col-sm-4">
   	  	 			<div className="footermenu">
   	  	 				<ul>
   	  	 					<li><A to="/services" name="Our Services">Our Services</A></li>
   	  	 					<li><A to="/about-us" name="About us"></A></li>
   	  	 					<li><A to="/contact-us" name="Contact us"></A></li>
   	  	 					<li><A to="/videos" name="Videos">Videos</A></li>
   	  	 					<li><A to="/blogs" name="Blogs">Blogs</A></li>
   	  	 				</ul>
   	  	 			</div>
   	  	 		</div>
   	  	 		<div className="col-sm-8">
   	  	 			{/* <div className="footerSub">
   	  	 				<h2>Get all the latest updates</h2>
   	  	 				<div className="subform">
   	  	 					<div className="sub_left"><input placeholder="Enter You email Addrress" /></div>
   	  	 					<div className="sub_right"><button>Subscribe</button></div>
   	  	 				</div>
   	  	 				<div className="socialMedia">
   	  	 					<ul>
   	  	 						<li>Follow us</li>
   	  	 						<li><A to="https://www.facebook.com/profile.php?id=100015259099417" name={<i className="fa fa-facebook"></i>}></A></li>
								<li><A to="https://twitter.com/Guruclasses1" name={<i className="fa fa-twitter"></i>}></A></li>
								<li><A to="https://www.linkedin.com/in/guru-classes-gurugram-053529135/" name={<i className="fa fa-linkedin"></i>}></A></li>
								<li><A to="https://www.youtube.com/@MathsByRamSir" name={<i className="fa fa-youtube"></i>}></A></li>
   	  	 					</ul>
   	  	 				</div>
   	  	 			</div> */}
						  <div className="contentForm">
                       <div className="fill_it">Reach Us</div>
                       <div className="contentFormArea">
                           <h2>Get In Touch</h2>
                           <p>We'll Respond in couple of time</p>
                           <ul>
                          <li><span><i className="fa fa-home"></i></span>H.No. 391, Hada Gali, Wazirabad, Sector 52, Gurugram, Haryana, India 122003</li>
                          <li><span><i className="fa fa-phone" ></i></span><A to="tel:919999953835" name="(+91) 9999953835" /></li>
                          <li><span><i className="fa fa-envelope-o" ></i></span> <A to="mailto:info@guruclasses.co.in" name="info@guruclasses.co.in" /></li>
                          <li><span><i className="fa fa-envelope-o" ></i></span> <A to="mailto:rameshwar92@gmail.com" name="rameshwar92@gmail.com" /></li>
                        </ul>
						<div className="socialMedia">
   	  	 					<ul>
   	  	 						<li>Follow us</li>
   	  	 						<li><A to="https://www.facebook.com/profile.php?id=100015259099417" name={<i className="fa fa-facebook"></i>}></A></li>
								<li><A to="https://twitter.com/Guruclasses1" name={<i className="fa fa-twitter"></i>}></A></li>
								<li><A to="https://www.linkedin.com/in/guru-classes-gurugram-053529135/" name={<i className="fa fa-linkedin"></i>}></A></li>
								<li><A to="https://www.youtube.com/@MathsByRamSir" name={<i className="fa fa-youtube"></i>}></A></li>
   	  	 					</ul>
   	  	 				</div>
                       </div>
                   </div>
   	  	 		</div>
   	  	 	</div>
   	  	 </div>
   	  </div>
   	</div>
   </footer>
   <div className="copyright">
   	  <div className="container">
	   	  	<div className="row">
	   	  		<div className="col-sm-7">
	   	  			<p>&copy; guruclasses.co.in. All rights reserved.</p>
	   	  		</div>
	   	  		<div className="col-sm-5">
	   	  			<div className="copymenu">
	   	  				<ul>
	   	  					<li><A to="/disclaimer" name="Disclaimer" /></li>
	   	  					<li><A to="/privacy-policy" name="Privacy Policy" /></li>
	   	  				</ul>
	   	  			</div>
	   	  		</div>
	   	  	</div>
   	   </div>
   </div>
    </div>
  )
}

export default Footer
