import React from 'react'
import Lottie from "lottie-react";
import weare from "./../../img/Json/class_english-teacher.json";
export default function WeAre() {
  return (
    <div>
    <div id="about-us" className="about-us-section">
			<div className="container">
				<div className="row">
					<div className="col-md-5 col-lg-4">
						<div className="">
					     	<Lottie animationData={weare} loop={true} />
						</div>
						
					</div>
					<div className="col-md-7 col-lg-8">
						<div className="about-us-text">
							<div className="section-title relative-position mb20 headline text-left"  >
								<span className="subtitle ml42 text-uppercase ml-5">SORT ABOUT US</span>
								<h2>Welcome to <span title='Indian Technical Computers'>Guru Classes </span>
								</h2>
								<p>Welcome to Guru Classes, a premier home tuition service provider for CBSE, ICSE, IGCSE, and IB curriculum. We believe that every student has the potential to succeed, regardless of their background, learning style or availability. So we also offer online tuition & small group tuition along with home tuition services to meet the needs of all learners.
</p>
<p>
Our tutors are highly qualified and experienced, They use a variety of teaching methods and techniques to create a personalized learning experience for each student. We provide experts for a wide range of subjects.
</p>
							</div>
							<div className="about-content-text">
								
								<div className="about-list mb65 ul-li-block "  >
									<ul>
										<li>Professional and experienced since 2017</li>
										<li>We Connect Learners to the best tutors Worldwide</li>
										<li>Our mission increasing global access to quality education</li>
										<li>50+ Online available courses</li>
									</ul>
								</div>
								{/* <div className="about-btn "  >
									<div className="genius-btn gradient-bg text-center text-uppercase ul-li-block bold-font">
										<span>About Us <i className="fas fa-caret-right"></i></span>
									</div>
									<div className="genius-btn gradient-bg text-center text-uppercase ul-li-block bold-font">
										<span>contact us <i className="fas fa-caret-right"></i></span>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div></div>
  )
}
