import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import About from "./Pages/About/About"
import Course from './Pages/Home/Course';
import Contact from './Pages/Contact/Contact'
import ScrollToTop from './Pages/ScrollTop/ScrollToTop'
// import Certificate from './Pages/Certificate/Certificate';
import Gallery from './Pages/Gallery/Gallery';
import Testimonial from './Components/Testimonial/Testimonial';
import Tutors from './Pages/Home/Tutors';
import OurServices from './Pages/OurServices/OurServices';
import Disclaimer from './Pages/desclaimer/OurServices';
import PrivacyPolicy from './Pages/ privacyPolicy/ privacyPolicy';
import Videos from './Pages/Videos/Videos';
import BlogHome from './Pages/Blogs/Home/BlogHome';
import BlogDetails from './Pages/Blogs/blogDetails/BlogDetails';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
         <ScrollToTop /> 
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/services" element={<OurServices />} />
              <Route path="/contact-us" element={<Contact />} />
               <Route path="/tutors" element={<Tutors />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} /> 
              <Route path='/videos' element={<Videos />} /> 
              <Route path='/blogs' element={<BlogHome />} /> 
              <Route path="/blogs/:cat/:postname" element={<BlogDetails />} />
               {/*<Route path="/request-a-tutor" element={<RequestAtutor />} />
              <Route path="/blogs" element={<BlogHome />} />
              <Route path="/login" element={<Login />} /> */}
            </Routes>
            <Testimonial />
            <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
