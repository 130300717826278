import React from 'react'
import Banner from '../../Components/Banner/Banner';
import Breadcum from '../../Components/Breadcum/Breadcum';



const  PrivacyPolicy = () => {
  return (
    <div>
      <Banner bl="S" l1="Se" l2="rv" l3="ic" l4="es" />
      <div className='otherpagebanner'>
        <Breadcum path='/' name="Home" pagename="Our Services" />
      </div>
      <div className="ourService">
        <div className="container">
          <div className="section-title mb-4 headline text-left "  >
            
            <h2><span> Privacy Policy</span></h2>
          </div>
          <p>
          Guru Classes is committed to protecting the privacy of our customers and their personal information. This privacy policy explains how we collect, use, and disclose your personal information.
          </p>
          <strong>
What information do we collect?
</strong>
<p>
We collect personal information from you when you visit our website, create an account, or book a tutoring session. This information may include your name, email address, phone number, address, date of birth, and academic information.
</p>
<strong>
How do we use your information?
</strong>
<p>
We use your personal information to provide you with our tutoring services, to communicate with you, and to improve our services. We may also use your personal information to send you marketing materials, but you can opt out of receiving these materials at any time.
</p>
<strong>
How do we disclose your information?
</strong>
<p>
We do not disclose your personal information to any third parties without your consent. However, we may disclose your personal information to our tutors in order to provide you with tutoring services. We may also disclose your personal information to third-party service providers who help us to operate our business, such as payment processors and email marketing services.
</p>
<strong>
How do we protect your information?
</strong>
<p>
We use a variety of security measures to protect your personal information from unauthorized access, use, or disclosure. These measures include encryption, firewalls, and access controls.
</p>
<strong>
Your choices
</strong>
<p>
You have the right to access, correct, and delete your personal information. You also have the right to opt out of receiving marketing materials from us. To exercise any of these rights, please contact us at guruclasses.in@gmail.com.
</p>
<strong>
Changes to this privacy policy
</strong>
<p>
We may update this privacy policy from time to time. If we make any changes, we will post the updated privacy policy on our website.
</p>
<strong>
Contact us
</strong>
<p>
If you have any questions about this privacy policy, please contact us at guruclasses.in@gmail.com
</p>
        </div>
      </div>
      
    </div>
  )
}

export default PrivacyPolicy
