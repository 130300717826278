import React from 'react'
import Banner from '../../Components/Banner/Banner';
import Breadcum from '../../Components/Breadcum/Breadcum';



const Videos = () => {
  return (
    <div>
      <Banner bl="S" l1="Se" l2="rv" l3="ic" l4="es" />
      <div className='otherpagebanner'>
        <Breadcum path='/videos' name="Home" pagename="Videos" />
      </div>
      <div className="ourService">
        <div className="container">
          <div className="section-title mb-4 headline text-center "  >
            
            <h2><span>  Videos</span></h2>
          </div>
          <div className='row'>
            {/* <div className='col-sm-6'>
                <div className='videosboxx'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/B9urguZ2pcw?si=iPPRjjBYP-WfuMDK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div> */}
            <div className='col-sm-6'>
                <div className='videosboxx'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/SuiqfLj8olI?si=6ldRS8Mn_1f3HjGq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
            
          </div>

        </div>
      </div>
      
    </div>
  )
}

export default Videos
