import React from 'react'
// import Lottie from "lottie-react";
// import animationData from './../../img/Json/class_banner2.json';
 import A from "./../../Components/Link/A"
// import banner1 from './../../img/bannertxt.webp';
// import banner2 from './../../img/banner2.png';
import video from './../../img/banner_video.mp4';
// import girljump from './../../img/girljump.webp';
const Banner = () => {
  // const videoSource = './../../img/banner_video.mp4';
  const posterImage = './../../img/banner_video.jpeg';
  return (
    <>

    <div className='newBanner'>
    <video autoPlay loop muted poster={posterImage} id="background">
      <source src={video} type="video/mp4" />
      {/* Add additional source elements for different video formats if needed */}
    </video>
    <div className='bannertxt'>
       <div className='container'>
        <h2>HOW WILL IT WORKS</h2>
        <h3>Guru Classes provide one to one quality home tuitions</h3>
        <p>A Guru Classes consultant will come out to your home and assess your child's requirements...</p>
        <A to="/services" name="View More" />
        </div>
    </div>
      {/* <div className='girljump'>
        <img src={girljump} className="img-fluid" alt='' />
      </div>
      <div className='banner_content'>
          <div className='container'>
              <div className='row'>
                  <div className='col-sm-5'>
                      <div className="bannerimg"><Lottie animationData={animationData} loop={true} /></div>
                  </div>
                  <div className='col-sm-6 offset-sm-1'>
                      <div className='bannertxt'>
                          <h2>HOW WILL IT WORKS</h2>
                          <h3>Guru Classes provide one to one quality home tuitions</h3>
                          <p>A Guru Classes consultant will come out to your home and assess your child's requirements...</p>
                          <A to="/" name="View More" />
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
    </div>
    {/* <div className='banner'>
       <div className='container'>
        <div className='row'>
            <div className='col-sm-7 order-sm-2'>
               <div className="bannerimg"><Lottie animationData={animationData} loop={true} /></div>
            </div>
            <div className='col-sm-5 order-sm-1'>
                <div  className='bannerStudy'>
                    <span>EXCELLENCE IN COMPUTERS</span>
                    <h1>Empowering Minds, Building a Better Learning Future</h1>
                </div>
                <div className='row'>
                    <div className='col-sm-4 col-5'>
                        <div>
                          <img src={banner2} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className='col-sm-8 col-7'>
                        <div className='bannerFeature'>
                           <ul>
                              <li>2 day free trial tuitions</li>
                              <li>Free Books</li>
                              <li>Free Complete Guidance</li>
                              <li>Throughout Scholarship process</li>
                           </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
       </div>
      
    </div> */}
    </>
  )
}

export default Banner
