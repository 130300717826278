import React, { useEffect, useState } from 'react';
import axios from 'axios';
import A from '../../../Components/Link/A';
import { useParams } from 'react-router-dom';


const BlogDetails = () => {
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState([]);
  const { postname } = useParams();
  useEffect(() => {
  
  console.log(postname)
    // Define the API endpoint URL
    const apiUrl = 'https://admin.guruclasses.co.in/wp-json/wp/v2/posts?slug='+postname;
    axios.get(apiUrl)
      .then((response) => {
        // Data contains the fetched posts
        setPosts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      const apicat = 'https://admin.guruclasses.co.in/wp-json/wp/v2/categories';
    axios.get(apicat)
      .then((response) => {
        // Data contains the fetched posts
        setCategory(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  console.log(category);
  return (
    <div className='otherpage mb-5'>
      <div className='container'>
        <div className="section-title mb-4 headline text-center">
          <span className="subtitle text-uppercase">Guru Classes Blogs</span>
          <h2><span>Blogs</span></h2>
        </div>
      </div>
      <div className='gallerysection'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-9'>
               <div className='row'>
               {posts.map((post) => {
                const {title, fimg_url} = post
                return(
                  <div  key={post.id} className="col-sm-12">
                  <div className=''>
                   <div className='blogbannerBoxsSmall'>
                   <div className='bannerblogheading'>
                       {/* <h2>{catUrls[index]}</h2> */}
                       <h2><A to={post.link} name={title.rendered} /></h2>
                  </div>
                   <img src={fimg_url} alt={title.rendered} className="img-fluid" />
                   </div>
                   <div className="post__content" dangerouslySetInnerHTML={{__html: post.content.rendered}}></div>
                </div>
                </div>
                )
               })}
               </div>
            </div>
            <div className='col-sm-3'>
                <div className='blogSidebar'>
                    <h2>Categories</h2>
                    <ul>
                    {
                        category.map((item, index) => {
                            return(
                                <div>
                                <li>{item.name}</li>
                                </div>
                            )
                        })
                            }
                    </ul>
                </div>
                
            </div>
          
          </div>
          </div>
        </div>
      </div>
  );
};

export default BlogDetails;