import React from 'react'
import Banner from '../../Components/Banner/Banner';
import Breadcum from './../../Components/Breadcum/Breadcum';
import persnal from "./../../img/personal_teacher.svg"
import service_2 from "./../../img/service_2.svg"
import service_3 from "./../../img/service_3.svg"
import service_4 from "./../../img/service_4.svg"
import service_5 from "./../../img/service_5.svg"
import service_6 from "./../../img/service_6.svg"


const OurServices = () => {
  return (
    <div>
      <Banner bl="S" l1="Se" l2="rv" l3="ic" l4="es" />
      <div className='otherpagebanner'>
        <Breadcum path='/' name="Home" pagename="Our Services" />
      </div>
      <div className="ourService">
        <div className="container">
          <div className="section-title mb-4 headline text-left "  >
            
            <h2><span>One to One</span> Classes.</h2>
          </div>
          <p className="text-justify">At Guru Classes, we offer a wide range of tutoring services to students of all ages and abilities. We specialize in home tuition, online tuition, and small group tuition.</p>
          <div className='ourServicebox'>
            <h3>Subjects We Cover:</h3>
            <p className="text-justify">We offer home tuition services for a wide range of subjects:</p>
            <ul className="ourlist">
              <li><strong> Mathematics</strong></li>
              <li><strong> Science (Physics, Chemistry, Biology)</strong></li>
              <li><strong> English</strong></li>
              <li><strong> Social Studies</strong></li>
              <li><strong> Languages (Hindi, French, Spanish, etc.)</strong></li>
              <li><strong> Computer Science</strong></li>
              <li><strong> Economics</strong></li>
              <li><strong> Accountancy</strong></li>
              <li><strong> Business Studies</strong></li>
            </ul>
            <p className='text-justify'>Whether you need help with a specific subject or want to improve your overall grades, Guru Classes can help.</p>
            <h3>Contact Us Today</h3>
            <p className='text-justify'>
To learn more about our tutoring services or to book a free consultation, please contact us today. We look forward to helping you succeed!


            </p>
          </div>
        </div>
      </div>
      <div>
        <div className='container'>
          <div className='serviceSec'>
            <div className='row'>
              <div className='col-sm-5'>
                <img src={persnal} alt="" className='img-fluid' />
              </div>
              <div className='col-sm-7'>
                <div className='serviceContent'>
                  <h2><span>Home </span> Tuition</h2>
                  <p>Our home tuition services provide students with the opportunity to receive one-on-one attention from a qualified tutor in the comfort of their own home. This is a great option for students who need extra help with a particular subject or who want to accelerate their learning.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='serviceSec'>
            <div className='row'>
              <div className='col-sm-5 order-sm-2'>
                <img src={service_2} alt="" className='img-fluid' />
              </div>
              <div className='col-sm-7 '>
                <div className='serviceContent'>
                  <h2><span>Online</span> Tuition</h2>
                  <p>Our online tuition services provide students with the opportunity to learn from qualified tutors from anywhere in the world. This is a great option for students who travel frequently or who live in remote areas.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='serviceSec'>
            <div className='row'>
              <div className='col-sm-5'>
                <img src={service_3} alt="" className='img-fluid' />
              </div>
              <div className='col-sm-7'>
                <div className='serviceContent'>
                  <h2><span>Small Group </span> Tuition</h2>
                  <p>Our small group tuition services provide students with the opportunity to learn from a qualified tutor in a small group setting. This is a great option for students who want to benefit from the interaction with other students while still receiving personalized attention from the tutor with comparative less fee.
</p>
<p>
All of our tutoring services are tailored to the individual needs of each student. Our tutors will work with you and your child to develop a learning plan that will help them to reach their full academic potential.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='serviceSec'>
            <div className='row'>
              <div className='col-sm-5 order-sm-2'>
                <img src={service_4} alt="" className='img-fluid' />
              </div>
              <div className='col-sm-7 '>
                <div className='serviceContent'>
                  <h2><span>Review weak area</span> & homework</h2>
                  <p>Our tutors will always help your child review the areas in which they may be struggling and assist them in completing homework. Your child’s homework will no longer be a stressful and will be transformed into a highly effective learning experience.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='serviceSec'>
            <div className='row'>
              <div className='col-sm-5'>
                <img src={service_5} alt="" className='img-fluid' />
              </div>
              <div className='col-sm-7'>
                <div className='serviceContent'>
                  <h2><span>Continuous feedback</span> & regular assessment</h2>
                  <p>Feedback that is regularly provided is the key to accelerated learning and improved progress. Your child will be assessed regularly (every 3 weeks), and every session will have a built in assessment element.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='serviceSec'>
            <div className='row'>
              <div className='col-sm-5 order-sm-2'>
                <img src={service_6} alt="" className='img-fluid' />
              </div>
              <div className='col-sm-7'>
                <div className='serviceContent'>
                  <h2><span>Increase confidence</span> & motivation</h2>
                  <p>Improvements in performance and test results will increase your child’s confidence and self-esteem which will give them the extra motivation.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurServices
