import React, {useEffect, useState} from 'react'
import OwlCarousel from 'react-owl-carousel';


import hardik from "./../../img/hardik-sharma-guru-classes-co-in.jpg"
import sahar from "./../../img/sahar-lal-guru-classes-co-in.jpg"
import nishant from "./../../img/nishant-kumar-guru-classes-co-in.jpg"
import abhijeet from "./../../img/abhijeet-guru-classes-co-in.jpg"
import img1 from "./../../img/guruclasses-review-img1.jpg"
import img2 from "./../../img/guruclasses-review-img2.jpg"

const Testimonial = () => {
    const [options, setOptions] = useState({
        margin: 15,
        loop: true,
        autoplay: true,
        responsiveClass: true,
        nav: false,
        dots: true,
        responsive: {
          0: {
            items: 1.5,
          },
          600: {
            items: 2,
          },
        },
      });
    
      useEffect(() => {
        setOptions((prevOptions) => {
          return {
            ...prevOptions,
            responsive: {
              0: {
                items: 1.5,
              },
              600: {
                items: 2,
              },
            },
          };
        });
      }, []);
  return (
    <div>
    <section id="why-choose" className="testimonail backgroud-style">
          <div className="container">
              <div className="section-title mb-4 headline text-center ">
                  <span className="subtitle text-uppercase"><span title='Guru classes.'>TESTIMONIAL</span></span>
                  <h2 >What Student & Guardian's Say  <span> <span title='Guru classes.'>About Us.</span></span></h2>
              </div>
              <div className='row justify-content-sm-center'>
                <div className='col-sm-7'>
                     <p className='tutorstxt'>Our students & Guardian's feedback motivate us to grow more and more....</p>
                </div>
              </div>
              <OwlCarousel className='owl-theme' {...options}>
                    <div className='testiCard'>
                        <div className='testCommectbox'>
                            <div className='testiImg'>
                                <img src={hardik} alt="Hardik" />
                            </div>
                            <p><i className='fa fa-quote-left'></i> Guru classes provide intelligent and soft speaking teachers. Specially Ram sir's teaching method is very easy and understandable. I recommended him to other student who want to develop an interest in maths and science subject. <i className='fa fa-quote-right'></i></p>
                            <h3 className='testName'>
                               <a href="https://www.google.com/maps/contrib/113976998427127735221/reviews/@28.426217,77.096765,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1" target="_blank">Hardhik Sharma</a>
                            </h3>
                            <span>(GD Goenka Public School, Sector-48)</span>
                        </div>
                    </div>
                    <div className='testiCard'>
                        <div className='testCommectbox yellowbg'>
                            <div className='testiImg'>
                                <img src={sahar} alt="Sahar" />
                            </div>
                            <p><i className='fa fa-quote-left'></i> It is the best home tuitions provider. I got Maths, Science and Hindi tutors from Guru classes. I found best tutors for these subjects and got lots of improvement. Strongly recommended Guru classes for board exams. <i className='fa fa-quote-right'></i></p>
                            <h3 className='testName'><a href="https://www.google.com/maps/contrib/115463445634536118361/reviews/@28.4283515,77.099088,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1" target="_blank">Sahar Lal</a></h3>
                            <span>(Shalom Hills International School, Sector-43)</span>
                        </div>
                    </div>
                    <div className='testiCard'>
                        <div className='testCommectbox'>
                            <div className='testiImg'>
                                <img src={nishant} alt="Nishant" />
                            </div>
                            <p><i className='fa fa-quote-left'></i> Earlier i was weak in maths but now after geting tuitions from Guru classes have improved a lot. Teachers are loving in nature. Guru classes is the best to those students who want to improve and build interest in thier particular subjects <i className='fa fa-quote-right'></i></p>
                            <h3 className='testName'><a href="https://www.google.com/maps/contrib/113191638512865901085/reviews/@28.426217,77.096765,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1" target="_blank">Nishant Kumar</a></h3>
                      <span>(Shalom Hills International School, Sector-43)</span>
                        </div>
                    </div>
                    <div className='testiCard'>
                        <div className='testCommectbox yellowbg'>
                            <div className='testiImg'>
                                <img src={abhijeet} alt="Abhijeet" />
                            </div>
                            <p><i className='fa fa-quote-left'></i> One of the teacher i have ever met. He is very soft spoken and make me well understant the questions and the topics well. Will love to continue with him!!!!!!! <br />Thanks Sir <i className='fa fa-quote-right'></i></p>
                            <h3 className='testName'><a href="https://www.google.com/maps/contrib/117887097778720205708/reviews?sa=X&ved=0ahUKEwjKjqX5nfzSAhWLQY8KHY0wDBkQvvQBCA8" target="_blank">Abhijit jayarajan</a></h3>
                      <span>(Shalom Hills International School, Sector-43)</span>
                        </div>
                    </div>
                    <div className='testiCard'>
                        <div className='testCommectbox'>
                            <div className='testiImg'>
                                <img src={img1} alt="Smira's Guardian" />
                            </div>
                            <p><i className='fa fa-quote-left'></i> It was a wonderful journey with Rameshwar sir. We are completely satisfied with his study pattern. He has thorough knowledge of Maths subject. His teaching pattern is amazing . Thank you once again for mentoring my daughter <i className='fa fa-quote-right'></i></p>
                            <h3 className='testName'><a href="https://www.google.com/maps/contrib/107122333746250370784/reviews?sa=X&ved=0ahUKEwjPqK-EqfzSAhWHp48KHQZ9AeoQvvQBCAk" target="_blank">Smira's Guardian</a></h3>
                      <span>M2K White House, Sec-57, GGN</span>
                        </div>
                    </div>
                    <div className='testiCard'>
                        <div className='testCommectbox yellowbg'>
                            <div className='testiImg'>
                                <img src={img2} />
                            </div>
                            <p><i className='fa fa-quote-left'></i> Very satisfied. He has provided good guidance to my son for Maths subject.  From not performing at all in Maths my son after taking class from Ram sir, is now more than  average and more confident about the subject. <i className='fa fa-quote-right'></i></p>
                            <h3 className='testName'><a href="https://www.google.com/maps/contrib/117887097778720205708/reviews?sa=X&ved=0ahUKEwip1pybqfzSAhVMNo8KHXZVDwIQvvQBCBk" target="_blank">Abhijit's Father</a></h3>
                      <span>Blossom-II, Sec-51, GGN</span>
                            <span></span>
                        </div>
                    </div>
                </OwlCarousel>
          </div>
      </section>
  </div>
  )
}

export default Testimonial
