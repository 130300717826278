import React from 'react'
import Banner from './Banner'
// import Course from './Course'
import Feature from './Feature'
// import StudentsSay from './StudentsSay'
import Tutors from './Tutors'
import WeAre from './WeAre'
import WhyChoose from './WhyChoose'

// import Phone from "./../../img/phone.png";

function Home() {
  
  return (
    <div>
		<Banner />
        <Feature />
		{/* <Course /> */}
        <WeAre />
        <WhyChoose />
		<Tutors />
        {/* <StudentsSay /> */}
		{/* 

		<section id="why-choose-us" className="why-choose-us-section">
			<div className="jarallax  backgroud-style">
				<div className="container">
					<div className="section-title mb20 headline text-center "  >
						<span className="subtitle text-uppercase">GENIUS ADVANTAGES</span>
						<h2>Reason <span>Why Choose Genius.</span></h2>
					</div>
					<div id="service-slide-item" className="service-slide">
						<div className="service-text-icon "  >
							<div className="service-icon float-left">
								<i className="text-gradiant flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler"></i>
							</div>
							<div className="service-text">
								<h3 className="bold-font">The Power Of Education.</h3>
								<p>Lorem ipsum dolor sit amet consectuerer adipiscing elit set diam nonnumynibh euismod tincidun laoreet.</p>
							</div>
						</div>
						<div className="service-text-icon "  >
							<div className="service-icon float-left">
								<i className="text-gradiant flaticon-clipboard-with-pencil"></i>
							</div>
							<div className="service-text">
								<h3 className="bold-font">Best Online Education.</h3>
								<p>Lorem ipsum dolor sit amet consectuerer adipiscing elit set diam nonnumynibh euismod tincidun laoreet.</p>
							</div>
						</div>
						<div className="service-text-icon "  >
							<div className="service-icon float-left">
								<i className="text-gradiant flaticon-list"></i>
							</div>
							<div className="service-text">
								<h3 className="bold-font">Education For All Student.</h3>
								<p>Lorem ipsum dolor sit amet consectuerer adipiscing elit set diam nonnumynibh euismod tincidun laoreet.</p>
							</div>
						</div>
						<div className="service-text-icon "  >
							<div className="service-icon float-left">
								<i className="text-gradiant flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler"></i>
							</div>
							<div className="service-text">
								<h3 className="bold-font">The Power Of Education.</h3>
								<p>Lorem ipsum dolor sit amet consectuerer adipiscing elit set diam nonnumynibh euismod tincidun laoreet.</p>
							</div>
						</div>
						<div className="service-text-icon">
							<div className="service-icon float-left">
								<i className="text-gradiant flaticon-clipboard-with-pencil"></i>
							</div>
							<div className="service-text">
								<h3 className="bold-font">Best Online Education.</h3>
								<p>Lorem ipsum dolor sit amet consectuerer adipiscing elit set diam nonnumynibh euismod tincidun laoreet.</p>
							</div>
						</div>
						<div className="service-text-icon">
							<div className="service-icon float-left">
								<i className="text-gradiant flaticon-list"></i>
							</div>
							<div className="service-text">
								<h3 className="bold-font">Education For All Student.</h3>
								<p>Lorem ipsum dolor sit amet consectuerer adipiscing elit set diam nonnumynibh euismod tincidun laoreet.</p>
							</div>
						</div>
						<div className="service-text-icon">
							<div className="service-icon float-left">
								<i className="text-gradiant flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler"></i>
							</div>
							<div className="service-text">
								<h3 className="bold-font">The Power Of Education.</h3>
								<p>Lorem ipsum dolor sit amet consectuerer adipiscing elit set diam nonnumynibh euismod tincidun laoreet.</p>
							</div>
						</div>
						<div className="service-text-icon">
							<div className="service-icon float-left">
								<i className="text-gradiant flaticon-clipboard-with-pencil"></i>
							</div>
							<div className="service-text">
								<h3 className="bold-font">Best Online Education.</h3>
								<p>Lorem ipsum dolor sit amet consectuerer adipiscing elit set diam nonnumynibh euismod tincidun laoreet.</p>
							</div>
						</div>
						<div className="service-text-icon">
							<div className="service-icon float-left">
								<i className="text-gradiant flaticon-list"></i>
							</div>
							<div className="service-text">
								<h3 className="bold-font">Education For All Student.</h3>
								<p>Lorem ipsum dolor sit amet consectuerer adipiscing elit set diam nonnumynibh euismod tincidun laoreet.</p>
							</div>
						</div>
					</div>
		
					<div  className="testimonial-slide">
						<div className="section-title-2 mb65 headline text-left "  >
							<h2>Students <span>Testimonial.</span></h2>
						</div>

						<div id="testimonial-slide-item" className="testimonial-slide-area">
							<div className="student-qoute "  >
								<p>“This was our first time lorem ipsum and we <b> were very pleased with the whole experience</b>. Your price was lower than other companies. Our experience was good from start to finish, so we’ll be back in the future lorem ipsum diamet.”</p>
								<div className="student-name-designation">
									<span className="st-name bold-font">Robertho Garcia </span>
									<span className="st-designation">Graphic Designer</span>
								</div>
							</div>

							<div className="student-qoute "  >
								<p>“This was our first time lorem ipsum and we <b> were very pleased with the whole experience</b>. Your price was lower than other companies. Our experience was good from start to finish, so we’ll be back in the future lorem ipsum diamet.”</p>
								<div className="student-name-designation">
									<span className="st-name bold-font">Robertho Garcia </span>
									<span className="st-designation">Graphic Designer</span>
								</div>
							</div>

							<div className="student-qoute "  >
								<p>“This was our first time lorem ipsum and we <b> were very pleased with the whole experience</b>. Your price was lower than other companies. Our experience was good from start to finish, so we’ll be back in the future lorem ipsum diamet.”</p>
								<div className="student-name-designation">
									<span className="st-name bold-font">Robertho Garcia </span>
									<span className="st-designation">Graphic Designer</span>
								</div>
							</div>

							<div className="student-qoute">
								<p>“This was our first time lorem ipsum and we <b> were very pleased with the whole experience</b>. Your price was lower than other companies. Our experience was good from start to finish, so we’ll be back in the future lorem ipsum diamet.”</p>
								<div className="student-name-designation">
									<span className="st-name bold-font">Robertho Garcia </span>
									<span className="st-designation">Graphic Designer</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	
		<section id="latest-area" className="latest-area-section">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<div className="latest-area-content  " >
							<div className="section-title-2 mb65 headline text-left">
								<h2>Latest <span>News.</span></h2>
							</div>
							<div className="latest-news-posts">
								<div className="latest-news-area">
									<div className="latest-news-thumbnile relative-position">
										<img src="assets/img/blog/lb-1.jpg" alt="" />
										<div className="hover-search">
											<i className="fas fa-search"></i>
										</div>
										<div className="blakish-overlay"></div>
									</div>
									<div className="date-meta">
										<i className="fas fa-calendar-alt"></i> 26 April 2018
									</div>
									<h3 className="latest-title bold-font"><span>Affiliate Marketing A Beginner’s Guide.</span></h3>
									<div className="course-viewer ul-li">
										<ul>
											<li><span><i className="fas fa-user"></i> 1.220</span></li>
											<li><span><i className="fas fa-comment-dots"></i> 1.015</span></li>
										</ul>
									</div>
								</div>
						

								<div className="latest-news-posts">
									<div className="latest-news-area">
										<div className="latest-news-thumbnile relative-position">
											<img src="assets/img/blog/lb-2.jpg" alt="" />
											<div className="hover-search">
												<i className="fas fa-search"></i>
											</div>
											<div className="blakish-overlay"></div>
										</div>
										<div className="date-meta">
											<i className="fas fa-calendar-alt"></i> 26 April 2018
										</div>
										<h3 className="latest-title bold-font"><span>No.1 The Best Online Course 2018.</span></h3>
										<div className="course-viewer ul-li">
											<ul>
												<li><span><i className="fas fa-user"></i> 1.220</span></li>
												<li><span><i className="fas fa-comment-dots"></i> 1.015</span></li>
											</ul>
										</div>
									</div>
								
								</div>

								<div className="view-all-btn bold-font">
									<span>View All News <i className="fas fa-chevron-circle-right"></i></span>
								</div>
							</div>
						</div>
					</div>
		
					<div className="col-md-4">
						<div className="latest-area-content " >
							<div className="section-title-2 mb65 headline text-left">
								<h2>Upcoming <span>Events.</span></h2>
							</div>
							<div className="latest-events">
								<div className="latest-event-item">
									<div className="events-date  relative-position text-center">
										<div className="gradient-bdr"></div>
										<span className="event-date bold-font">22</span>
										April 2018
									</div>
									<div className="event-text">
										<h3 className="latest-title bold-font"><span>Fully Responsive Web Design & Development.</span></h3>
										<div className="course-meta">
											<span className="course-category"><span>Web Design</span></span>
											<span className="course-author"><span>Koke</span></span>
										</div>
									</div>
								</div>
							</div>

							<div className="latest-events">
								<div className="latest-event-item">
									<div className="events-date  relative-position text-center">
										<div className="gradient-bdr"></div>
										<span className="event-date bold-font">07</span>
										August 2018
									</div>
									<div className="event-text">
										<h3 className="latest-title bold-font"><span>Introduction to Mobile Application Development.</span></h3>
										<div className="course-meta">
											<span className="course-category"><span>Web Design</span></span>
											<span className="course-author"><span>Koke</span></span>
										</div>
									</div>
								</div>
							</div>

							<div className="latest-events">
								<div className="latest-event-item">
									<div className="events-date  relative-position text-center">
										<div className="gradient-bdr"></div>
										<span className="event-date bold-font">30</span>
										Sept 2018
									</div>
									<div className="event-text">
										<h3 className="latest-title bold-font"><span>IOS Apps Programming & Development.</span></h3>
										<div className="course-meta">
											<span className="course-category"><span>Web Design</span></span>
											<span className="course-author"><span>Koke</span></span>
										</div>
									</div>
								</div>
							</div>

							<div className="view-all-btn bold-font">
								<a >Check Calendar   <i className="fas fa-calendar-alt"></i></span>
							</div>
						</div>
					</div>
			
					<div className="col-md-4">
						<div className="latest-area-content " >
							<div className="section-title-2 mb65 headline text-left">
								<h2>Latest <span>Video.</span></h2>
							</div>
							<div className="latest-video-poster relative-position mb20">
								<img src="assets/img/banner/v-1.jpg" alt="" />
								<div className="video-play-btn text-center gradient-bg">
									<a className="popup-with-zoom-anim" href="https://www.youtube.com/watch?v=-g4TnixUdSc"><i className="fas fa-play"></i></span>
								</div>
							</div>
							<div className="vidoe-text">
								<h3 className="latest-title bold-font"><span>Learning IOS Apps in Amsterdam.</span></h3>
								<p className="mb25">Lorem ipsum dolor sit amet, consectetuer delacosta adipiscing elit, sed diam nonummy.</p>
							</div>
							<div className="view-all-btn bold-font">
								<span>View All Videos <i className="fas fa-chevron-circle-right"></i></span>
							</div>
						</div>
					</div>
				
				</div>
			</div>
		</section>
	
		<section id="sponsor" className="sponsor-section">
			<div className="container">
				<div className="section-title-2 mb65 headline text-left "  >
					<h2>Genius <span>Sponsors.</span></h2>
				</div>
				<div className="sponsor-item sponsor-1 " >
					<div className="sponsor-pic text-center">
						<img src="assets/img/sponsor/s-1.jpg" alt="" />
					</div>
					<div className="sponsor-pic text-center">
						<img src="assets/img/sponsor/s-2.jpg" alt="" />
					</div>
					<div className="sponsor-pic text-center">
						<img src="assets/img/sponsor/s-3.jpg" alt="" />
					</div>
					<div className="sponsor-pic text-center">
						<img src="assets/img/sponsor/s-4.jpg" alt="" />
					</div>
					<div className="sponsor-pic text-center">
						<img src="assets/img/sponsor/s-5.jpg" alt="" />
					</div>
					<div className="sponsor-pic text-center">
						<img src="assets/img/sponsor/s-6.jpg" alt="" />
					</div>
					<div className="sponsor-pic text-center">
						<img src="assets/img/sponsor/s-6.jpg" alt="" />
					</div>
					<div className="sponsor-pic text-center">
						<img src="assets/img/sponsor/s-6.jpg" alt="" />
					</div>
					<div className="sponsor-pic text-center">
						<img src="assets/img/sponsor/s-6.jpg" alt="" />
					</div>
				</div>
			</div>
		</section>

		<section id="best-course" className="best-course-section">
			<div className="container">
				<div className="section-title mb45 headline text-center "  >
					<span className="subtitle text-uppercase">SEARCH OUR COURSES</span>
					<h2>Browse Our<span> Best Course.</span></h2>
				</div>
				<div className="best-course-area mb45">
					<div className="row">
						<div className="col-md-3">
							<div className="best-course-pic-text relative-position "  >
								<div className="best-course-pic relative-position">
									<img src="assets/img/course/bc-1.jpg" alt="" />
									<div className="trend-badge-2 text-center text-uppercase">
										<i className="fas fa-bolt"></i>
										<span>Trending</span>
									</div>
									<div className="course-price text-center gradient-bg">
										<span>$99.00</span>
									</div>
									<div className="course-rate ul-li">
										<ul>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
										</ul>
									</div>
									<div className="course-details-btn">
										<span>COURSE DETAIL <i className="fas fa-arrow-right"></i></span>
									</div>
									<div className="blakish-overlay"></div>
								</div>
								<div className="best-course-text">
									<div className="course-title mb20 headline relative-position">
										<h3><span>Fully Responsive Web Design &amp; Development.</span></h3>
									</div>
									<div className="course-meta">
										<span className="course-category"><span>Web Design</span></span>
										<span className="course-author"><span>250 Students</span></span>
									</div>
								</div>
							</div>
						</div>
				
						<div className="col-md-3">
							<div className="best-course-pic-text relative-position "  >
								<div className="best-course-pic relative-position">
									<img src="assets/img/course/bc-2.jpg" alt="" />
									<div className="course-price text-center gradient-bg">
										<span>$99.00</span>
									</div>
									<div className="course-rate ul-li">
										<ul>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
										</ul>
									</div>
									<div className="course-details-btn">
										<span>COURSE DETAIL <i className="fas fa-arrow-right"></i></span>
									</div>
									<div className="blakish-overlay"></div>
								</div>
								<div className="best-course-text">
									<div className="course-title mb20 headline relative-position">
										<h3><span>Fully Responsive Web Design &amp; Development.</span></h3>
									</div>
									<div className="course-meta">
										<span className="course-category"><span>Web Design</span></span>
										<span className="course-author"><span>250 Students</span></span>
									</div>
								</div>
							</div>
						</div>
					
						<div className="col-md-3">
							<div className="best-course-pic-text relative-position "  >
								<div className="best-course-pic relative-position">
									<img src="assets/img/course/bc-3.jpg" alt="" />
									<div className="course-price text-center gradient-bg">
										<span>$99.00</span>
									</div>
									<div className="course-rate ul-li">
										<ul>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
										</ul>
									</div>
									<div className="course-details-btn">
										<span>COURSE DETAIL <i className="fas fa-arrow-right"></i></span>
									</div>
									<div className="blakish-overlay"></div>
								</div>
								<div className="best-course-text">
									<div className="course-title mb20 headline relative-position">
										<h3><span>Fully Responsive Web Design &amp; Development.</span></h3>
									</div>
									<div className="course-meta">
										<span className="course-category"><span>Web Design</span></span>
										<span className="course-author"><span>250 Students</span></span>
									</div>
								</div>
							</div>
						</div>
					
						<div className="col-md-3">
							<div className="best-course-pic-text relative-position "  >
								<div className="best-course-pic relative-position">
									<img src="assets/img/course/bc-4.jpg" alt="" />
									<div className="course-price text-center gradient-bg">
										<span>$99.00</span>
									</div>
									<div className="course-rate ul-li">
										<ul>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
										</ul>
									</div>
									<div className="course-details-btn">
										<span>COURSE DETAIL <i className="fas fa-arrow-right"></i></span>
									</div>
									<div className="blakish-overlay"></div>
								</div>
								<div className="best-course-text">
									<div className="course-title mb20 headline relative-position">
										<h3><span>Fully Responsive Web Design &amp; Development.</span></h3>
									</div>
									<div className="course-meta">
										<span className="course-category"><span>Web Design</span></span>
										<span className="course-author"><span>250 Students</span></span>
									</div>
								</div>
							</div>
						</div>
					
						<div className="col-md-3">
							<div className="best-course-pic-text relative-position "  >
								<div className="best-course-pic relative-position">
									<img src="assets/img/course/bc-5.jpg" alt="" />
									<div className="course-price text-center gradient-bg">
										<span>$99.00</span>
									</div>
									<div className="course-rate ul-li">
										<ul>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
										</ul>
									</div>
									<div className="course-details-btn">
										<span>COURSE DETAIL <i className="fas fa-arrow-right"></i></span>
									</div>
									<div className="blakish-overlay"></div>
								</div>
								<div className="best-course-text">
									<div className="course-title mb20 headline relative-position">
										<h3><span>Fully Responsive Web Design &amp; Development.</span></h3>
									</div>
									<div className="course-meta">
										<span className="course-category"><span>Web Design</span></span>
										<span className="course-author"><span>250 Students</span></span>
									</div>
								</div>
							</div>
						</div>
					
						<div className="col-md-3">
							<div className="best-course-pic-text relative-position "  >
								<div className="best-course-pic relative-position">
									<img src="assets/img/course/bc-6.jpg" alt="" />
									<div className="trend-badge-2 text-center text-uppercase">
										<i className="fas fa-bolt"></i>
										<span>Trending</span>
									</div>
									<div className="course-price text-center gradient-bg">
										<span>$99.00</span>
									</div>
									<div className="course-rate ul-li">
										<ul>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
										</ul>
									</div>
									<div className="course-details-btn">
										<span>COURSE DETAIL <i className="fas fa-arrow-right"></i></span>
									</div>
									<div className="blakish-overlay"></div>
								</div>
								<div className="best-course-text">
									<div className="course-title mb20 headline relative-position">
										<h3><span>Fully Responsive Web Design &amp; Development.</span></h3>
									</div>
									<div className="course-meta">
										<span className="course-category"><span>Web Design</span></span>
										<span className="course-author"><span>250 Students</span></span>
									</div>
								</div>
							</div>
						</div>
					
						<div className="col-md-3">
							<div className="best-course-pic-text relative-position "  >
								<div className="best-course-pic relative-position">
									<img src="assets/img/course/bc-7.jpg" alt="" />
									<div className="course-price text-center gradient-bg">
										<span>$99.00</span>
									</div>
									<div className="course-rate ul-li">
										<ul>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
										</ul>
									</div>
									<div className="course-details-btn">
										<span>COURSE DETAIL <i className="fas fa-arrow-right"></i></span>
									</div>
									<div className="blakish-overlay"></div>
								</div>
								<div className="best-course-text">
									<div className="course-title mb20 headline relative-position">
										<h3><span>Fully Responsive Web Design &amp; Development.</span></h3>
									</div>
									<div className="course-meta">
										<span className="course-category"><span>Web Design</span></span>
										<span className="course-author"><span>250 Students</span></span>
									</div>
								</div>
							</div>
						</div>
						
						<div className="col-md-3">
							<div className="best-course-pic-text relative-position "  >
								<div className="best-course-pic relative-position">
									<img src="assets/img/course/bc-8.jpg" alt="" />
									<div className="course-price text-center gradient-bg">
										<span>$99.00</span>
									</div>
									<div className="course-rate ul-li">
										<ul>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
											<li><i className="fas fa-star"></i></li>
										</ul>
									</div>
									<div className="course-details-btn">
										<span>COURSE DETAIL <i className="fas fa-arrow-right"></i></span>
									</div>
									<div className="blakish-overlay"></div>
								</div>
								<div className="best-course-text">
									<div className="course-title mb20 headline relative-position">
										<h3><span>Fully Responsive Web Design &amp; Development.</span></h3>
									</div>
									<div className="course-meta">
										<span className="course-category"><span>Web Design</span></span>
										<span className="course-author"><span>250 Students</span></span>
									</div>
								</div>
							</div>
						</div>
					
					</div>
				</div>

				
			</div>
		</section>
	
		<section id="course-teacher" className="course-teacher-section">
			<div className="jarallax">
				<div className="container">
					<div className="section-title mb20 headline text-center "  >
						<span className="subtitle text-uppercase">OUR PROFESSIONAL</span>
						<h2>Genius Courses <span>Teachers.</span></h2>
					</div>

					<div className="teacher-list">
						<div className="row justify-content-center">
							<div className="col-md-3">
								<div className="teacher-img-content "  >
									<div className="teacher-cntent">
										<div className="teacher-social-name ul-li-block">
											<ul>
												<li><span><i className="fab fa-facebook-f"></i></span></li>
												<li><span><i className="fab fa-twitter"></i></span></li>
												<li><span><i className="fab fa-google-plus-g"></i></span></li>
											</ul>
											<div className="teacher-name">
												<span>Daniel
												Alvares</span>
											</div>
										</div>
										<div className="teacher-img-category">
											<div className="teacher-img">
												<img src="assets/img/teacher/t-1.jpg" alt="" />
												<div className="course-price text-uppercase text-center gradient-bg">
													<span>Featured</span>
												</div>
											</div>
											<div className="teacher-category float-right">
												<span className="st-name">Mobile Apps </span>
											</div>
										</div>
									</div>
								</div>
							</div>
					
							<div className="col-md-3">
								<div className="teacher-img-content "  >
									<div className="teacher-cntent">
										<div className="teacher-social-name ul-li-block">
											<ul>
												<li><span><i className="fab fa-facebook-f"></i></span></li>
												<li><span><i className="fab fa-twitter"></i></span></li>
												<li><span><i className="fab fa-google-plus-g"></i></span></li>
											</ul>
											<div className="teacher-name">
												<span>Juliana Hernandes</span>
											</div>
										</div>
										<div className="teacher-img-category">
											<div className="teacher-img">
												<img src="assets/img/teacher/t-2.jpg" alt="" />
												<div className="course-price text-uppercase text-center gradient-bg">
													<span>Featured</span>
												</div>
											</div>
											<div className="teacher-category float-right">
												<span className="st-name">Mobile Apps </span>
											</div>
										</div>
									</div>
								</div>
							</div>
					
							<div className="col-md-3">
								<div className="teacher-img-content "  >
									<div className="teacher-cntent">
										<div className="teacher-social-name ul-li-block">
											<ul>
												<li><span><i className="fab fa-facebook-f"></i></span></li>
												<li><span><i className="fab fa-twitter"></i></span></li>
												<li><span><i className="fab fa-google-plus-g"></i></span></li>
											</ul>
											<div className="teacher-name">
												<span>Berliana
												Luis</span>
											</div>
										</div>
										<div className="teacher-img-category">
											<div className="teacher-img">
												<img src="assets/img/teacher/t-3.jpg" alt="" />
												<div className="course-price text-uppercase text-center gradient-bg">
													<span>Featured</span>
												</div>
											</div>
											<div className="teacher-category float-right">
												<span className="st-name">IOS Apps </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						
							<div className="col-md-3">
								<div className="teacher-img-content "  >
									<div className="teacher-cntent">
										<div className="teacher-social-name ul-li-block">
											<ul>
												<li><span><i className="fab fa-facebook-f"></i></span></li>
												<li><span><i className="fab fa-twitter"></i></span></li>
												<li><span><i className="fab fa-google-plus-g"></i></span></li>
											</ul>
											<div className="teacher-name">
												<span>Johansen
												Doe</span>
											</div>
										</div>
										<div className="teacher-img-category">
											<div className="teacher-img">
												<img src="assets/img/teacher/t-4.jpg" alt="" />
												<div className="course-price text-uppercase text-center gradient-bg">
													<span>Featured</span>
												</div>
											</div>
											<div className="teacher-category float-right">
												<span className="st-name">Graphic </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						
							<div className="col-md-3">
								<div className="teacher-img-content "  >
									<div className="teacher-cntent">
										<div className="teacher-social-name ul-li-block">
											<ul>
												<li><span><i className="fab fa-facebook-f"></i></span></li>
												<li><span><i className="fab fa-twitter"></i></span></li>
												<li><span><i className="fab fa-google-plus-g"></i></span></li>
											</ul>
											<div className="teacher-name">
												<span>Lisha
												Chambelt</span>
											</div>
										</div>
										<div className="teacher-img-category">
											<div className="teacher-img">
												<img src="assets/img/teacher/t-6.jpg" alt="" />
												<div className="course-price text-uppercase text-center gradient-bg">
													<span>Featured</span>
												</div>
											</div>
											<div className="teacher-category float-right">
												<span className="st-name">Mobile Apps </span>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div className="col-md-3">
								<div className="teacher-img-content "  >
									<div className="teacher-cntent">
										<div className="teacher-social-name ul-li-block">
											<ul>
												<li><span><i className="fab fa-facebook-f"></i></span></li>
												<li><span><i className="fab fa-twitter"></i></span></li>
												<li><span><i className="fab fa-google-plus-g"></i></span></li>
											</ul>
											<div className="teacher-name">
												<span>Checilia
												Yeoung</span>
											</div>
										</div>
										<div className="teacher-img-category">
											<div className="teacher-img">
												<img src="assets/img/teacher/t-5.jpg" alt="" />
												<div className="course-price text-uppercase text-center gradient-bg">
													<span>Featured</span>
												</div>
											</div>
											<div className="teacher-category float-right">
												<span className="st-name">Mobile Apps </span>
											</div>
										</div>
									</div>
								</div>
							</div>
					
							<div className="col-md-3">
								<div className="teacher-img-content "  >
									<div className="teacher-cntent">
										<div className="teacher-social-name ul-li-block">
											<ul>
												<li><span><i className="fab fa-facebook-f"></i></span></li>
												<li><span><i className="fab fa-twitter"></i></span></li>
												<li><span><i className="fab fa-google-plus-g"></i></span></li>
											</ul>
											<div className="teacher-name">
												<span>Sean
												Fernandes</span>
											</div>
										</div>
										<div className="teacher-img-category">
											<div className="teacher-img">
												<img src="assets/img/teacher/t-7.jpg" alt="" />
												<div className="course-price text-uppercase text-center gradient-bg">
													<span>Featured</span>
												</div>
											</div>
											<div className="teacher-category float-right">
												<span className="st-name">Mobile Apps </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						
						</div>

						<div className="genius-btn gradient-bg text-center text-uppercase ul-li-block bold-font "  >
							<span>All teacher <i className="fas fa-caret-right"></i></span>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="best-product" className="best-product-section">
			<div className="container">
				<div className="section-title-2 mb65 headline text-left "  >
					<h2>Genius <span>Best Products.</span></h2>
				</div>
				<div id="best-product-slide-item" className="best-product-slide">
					<div className="product-img-text "  >
						<div className="product-img text-center mb20">
							<img src="assets/img/product/bp-1.png" alt="" />
						</div>
						<div className="product-text-content relative-position">
							<div className="best-title-price float-left">
								<div className="course-title headline">
									<h3><span>Mobile Apps Books.</span></h3>
								</div>
								<div className="price-start">
									Start from
									<span>$55.25</span>
								</div>
							</div>
							<div className="add-cart text-center">
								<i className="fas fa-cart-plus"></i>
							</div>
						</div>
					</div>

					<div className="product-img-text "  >
						<div className="product-img text-center mb20">
							<img src="assets/img/product/bp-2.png" alt="" />
						</div>
						<div className="product-text-content relative-position">
							<div className="best-title-price float-left">
								<div className="course-title headline">
									<h3><span>Mobile Apps Books.</span></h3>
								</div>
								<div className="price-start">
									Start from
									<span>$55.25</span>
								</div>
							</div>
							<div className="add-cart text-center">
								<i className="fas fa-cart-plus"></i>
							</div>
						</div>
					</div>
					<div className="product-img-text "  >
						<div className="product-img text-center mb20">
							<img src="assets/img/product/bp-3.png" alt="" />
						</div>
						<div className="product-text-content relative-position">
							<div className="best-title-price float-left">
								<div className="course-title headline">
									<h3><span>Mobile Apps Books.</span></h3>
								</div>
								<div className="price-start">
									Start from
									<span>$55.25</span>
								</div>
							</div>
							<div className="add-cart text-center">
								<i className="fas fa-cart-plus"></i>
							</div>
						</div>
					</div>
					<div className="product-img-text "  >
						<div className="product-img text-center mb20">
							<img src="assets/img/product/bp-4.png" alt="" />
						</div>
						<div className="product-text-content relative-position">
							<div className="best-title-price float-left">
								<div className="course-title headline">
									<h3><span>Mobile Apps Books.</span></h3>
								</div>
								<div className="price-start">
									Start from
									<span>$55.25</span>
								</div>
							</div>
							<div className="add-cart text-center">
								<i className="fas fa-cart-plus"></i>
							</div>
						</div>
					</div>
					<div className="product-img-text "  >
						<div className="product-img text-center mb20">
							<img src="assets/img/product/bp-1.png" alt="" />
						</div>
						<div className="product-text-content relative-position">
							<div className="best-title-price float-left">
								<div className="course-title headline">
									<h3><span>Mobile Apps Books.</span></h3>
								</div>
								<div className="price-start">
									Start from
									<span>$55.25</span>
								</div>
							</div>
							<div className="add-cart text-center">
								<i className="fas fa-cart-plus"></i>
							</div>
						</div>
					</div>
					<div className="product-img-text">
						<div className="product-img text-center mb20">
							<img src="assets/img/product/bp-2.png" alt="" />
						</div>
						<div className="product-text-content relative-position">
							<div className="best-title-price float-left">
								<div className="course-title headline">
									<h3><span>Mobile Apps Books.</span></h3>
								</div>
								<div className="price-start">
									Start from
									<span>$55.25</span>
								</div>
							</div>
							<div className="add-cart text-center">
								<i className="fas fa-cart-plus"></i>
							</div>
						</div>
					</div>
					<div className="product-img-text">
						<div className="product-img text-center mb20">
							<img src="assets/img/product/bp-3.png" alt="" />
						</div>
						<div className="product-text-content relative-position">
							<div className="best-title-price float-left">
								<div className="course-title headline">
									<h3><span>Mobile Apps Books.</span></h3>
								</div>
								<div className="price-start">
									Start from
									<span>$55.25</span>
								</div>
							</div>
							<div className="add-cart text-center">
								<i className="fas fa-cart-plus"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="faq" className="faq-section">
			<div className="container">
				<div className="section-title mb45 headline text-center "  >
					<span className="subtitle text-uppercase">GENIUS COURSE FAQ</span>
					<h2>Frequently<span> Ask & Questions</span></h2>
				</div>
				<div className="faq-tab">
					<div className="faq-tab-ques ul-li">
						<div className="tab-button text-center mb65 "  >
							<ul className="product-tab">
								<li className="active" rel="tab1">GENERAL </li>
								<li rel="tab2"> COURSES </li>
								<li rel="tab3"> TEACHERS </li>
								<li rel="tab4">  EVENTS  </li>
								<li rel="tab5">  OTHERS  </li>
							</ul>
						</div>
						
						<div className="tab-container">

							<div id="tab1" className="tab-content-1 pt35">
								<div className="row">
									<div className="col-md-6">
										<div className="ques-ans mb45 headline "  >
											<h3> What is Genius Courses?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>

										<div className="ques-ans mb45 headline "  >
											<h3> What Lorem Ipsum Dolor Sit Amet Consectuerer?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>
									</div>

									<div className="col-md-6">
										<div className="ques-ans mb45 headline "  >
											<h3> How to Register or Make An Account in Genius?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>

										<div className="ques-ans mb45 headline "  >
											<h3> Adipiscing Diamet Nonnumy Nibh Euismod?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>
									</div>
								</div>
							</div>
					

							<div id="tab2" className="tab-content-1 pt35">
								<div className="row">
									<div className="col-md-6">
										<div className="ques-ans mb45 headline "  >
											<h3> What is Genius Courses?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>

										<div className="ques-ans mb45 headline "  >
											<h3> What Lorem Ipsum Dolor Sit Amet Consectuerer?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>
									</div>

									<div className="col-md-6">
										<div className="ques-ans mb45 headline "  >
											<h3> How to Register or Make An Account in Genius?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>

										<div className="ques-ans mb45 headline "  >
											<h3> Adipiscing Diamet Nonnumy Nibh Euismod?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>
									</div>
								</div>
							</div>
						

							<div id="tab3" className="tab-content-1 pt35">
								<div className="row">
									<div className="col-md-6">
										<div className="ques-ans mb45 headline">
											<h3> What is Genius Courses?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>

										<div className="ques-ans mb45 headline">
											<h3> What Lorem Ipsum Dolor Sit Amet Consectuerer?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>
									</div>

									<div className="col-md-6">
										<div className="ques-ans mb45 headline">
											<h3> How to Register or Make An Account in Genius?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>

										<div className="ques-ans mb45 headline">
											<h3> Adipiscing Diamet Nonnumy Nibh Euismod?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>
									</div>
								</div>
							</div>
							

							<div id="tab4" className="tab-content-1 pt35">
								<div className="row">
									<div className="col-md-6">
										<div className="ques-ans mb45 headline">
											<h3> What is Genius Courses?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>

										<div className="ques-ans mb45 headline">
											<h3> What Lorem Ipsum Dolor Sit Amet Consectuerer?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>
									</div>

									<div className="col-md-6">
										<div className="ques-ans mb45 headline">
											<h3> How to Register or Make An Account in Genius?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>

										<div className="ques-ans mb45 headline">
											<h3> Adipiscing Diamet Nonnumy Nibh Euismod?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>
									</div>
								</div>
							</div>
						

							<div id="tab5" className="tab-content-1 pt35">
								<div className="row">
									<div className="col-md-6">
										<div className="ques-ans mb45 headline">
											<h3> What is Genius Courses?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>

										<div className="ques-ans mb45 headline">
											<h3> What Lorem Ipsum Dolor Sit Amet Consectuerer?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>
									</div>

									<div className="col-md-6">
										<div className="ques-ans mb45 headline">
											<h3> How to Register or Make An Account in Genius?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>

										<div className="ques-ans mb45 headline">
											<h3> Adipiscing Diamet Nonnumy Nibh Euismod?</h3>
											<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam volutpat. Ut wisi enim ad minim veniam.</p>
										</div>
									</div>
								</div>
							</div>
					
						</div>
						<div className="view-all-btn bold-font "  >
							<span>Make a Question <i className="fas fa-chevron-circle-right"></i></span>
						</div>
					</div>
				</div>
			</div>
		</section>
	
		<section id="course-category" className="course-category-section">
			<div className="container">
				<div className="section-title mb45 headline text-center "  >
					<span className="subtitle text-uppercase">COURSES CATEGORIES</span>
					<h2>Browse Courses<span> By Category.</span></h2>
				</div>
				<div className="category-item">
					<div className="row">
						<div className="col-md-3">
							<div className="category-icon-title text-center "  >
								<div className="category-icon">
									<i className="text-gradiant flaticon-technology"></i>
								</div>
								<div className="category-title">
									<h4>Responsive Website</h4>
								</div>
							</div>
						</div>
						

						<div className="col-md-3">
							<div className="category-icon-title text-center "  >
								<div className="category-icon">
									<i className="text-gradiant flaticon-app-store"></i>
								</div>
								<div className="category-title">
									<h4>IOS Applications</h4>
								</div>
							</div>
						</div>
					

						<div className="col-md-3">
							<div className="category-icon-title text-center "  >
								<div className="category-icon">
									<i className="text-gradiant flaticon-artist-tools"></i>
								</div>
								<div className="category-title">
									<h4>Graphic Design</h4>
								</div>
							</div>
						</div>
					

						<div className="col-md-3">
							<div className="category-icon-title text-center "  >
								<div className="category-icon">
									<i className="text-gradiant flaticon-business"></i>
								</div>
								<div className="category-title">
									<h4>Marketing</h4>
								</div>
							</div>
						</div>
						

						<div className="col-md-3">
							<div className="category-icon-title text-center "  >
								<div className="category-icon">
									<i className="text-gradiant flaticon-dna"></i>
								</div>
								<div className="category-title">
									<h4>Science</h4>
								</div>
							</div>
						</div>
					

						<div className="col-md-3">
							<div className="category-icon-title text-center "  >
								<div className="category-icon">
									<i className="text-gradiant flaticon-cogwheel"></i>
								</div>
								<div className="category-title">
									<h4>Enginering</h4>
								</div>
							</div>
						</div>
				

						<div className="col-md-3">
							<div className="category-icon-title text-center "  >
								<div className="category-icon">
									<i className="text-gradiant flaticon-technology-1"></i>
								</div>
								<div className="category-title">
									<h4>Photography</h4>
								</div>
							</div>
						</div>
				

						<div className="col-md-3">
							<div className="category-icon-title text-center "  >
								<div className="category-icon">
									<i className="text-gradiant flaticon-technology-2"></i>
								</div>
								<div className="category-title">
									<h4>Mobile Application</h4>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</section>

		<section id="contact-area" className="contact-area-section backgroud-style">
			<div className="container">
				<div className="contact-area-content">
					<div className="row">
						<div className="col-md-6">
							<div className="contact-left-content " >
								<div className="section-title  mb45 headline text-left">
									<span className="subtitle ml42  text-uppercase">CONTACT US</span>
									<h2><span>Get in Touch</span></h2>
									<p>
										Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet  ipsum dolor sit amet, consectetuer adipiscing elit.
									</p>
								</div>

								<div className="contact-address">
									<div className="contact-address-details">
										<div className="address-icon relative-position text-center float-left">
											<i className="fas fa-map-marker-alt"></i>
										</div>
										<div className="address-details ul-li-block">
											<ul>
												<li><span>Primary: </span>Last Vegas, 120 Graphic Street, US</li>
												<li><span>Second: </span>Califorinia, 88 Design Street, US</li>
											</ul>
										</div>
									</div>

									<div className="contact-address-details">
										<div className="address-icon relative-position text-center float-left">
											<i className="fas fa-phone"></i>
										</div>
										<div className="address-details ul-li-block">
											<ul>
												<li><span>Primary: </span>(100) 3434 55666</li>
												<li><span>Second: </span>(20) 3434 9999</li>
											</ul>
										</div>
									</div>

									<div className="contact-address-details">
										<div className="address-icon relative-position text-center float-left">
											<i className="fas fa-envelope"></i>
										</div>
										<div className="address-details ul-li-block">
											<ul>
												<li><span>Primary: </span>info@geniuscourse.com</li>
												<li><span>Second: </span>mail@genius.info</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="genius-btn mt60 gradient-bg text-center text-uppercase ul-li-block bold-font ">
								<span>Contact Us <i className="fas fa-caret-right"></i></span>
							</div>
						</div>

						<div className="col-md-6">
							<div id="contact-map" className="contact-map-section">
								<div id="google-map">
									<div id="googleMaps" className="google-map-container"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<footer>
			<section id="footer-area" className="footer-area-section">
				<div className="container">
					<div className="footer-content pb10">
						<div className="row">
							<div className="col-md-3">
								<div className="footer-widget "  >
									<div className="footer-logo mb35">
										<img src="assets/img/logo/f-logo.png" alt="" />
									</div>
									<div className="footer-about-text">
										<p>We take our mission of increasing global access to quality education seriously. We connect learners to the best universities and institutions from around the world.</p>
										<p>Lorem ipsum dolor sit amet we take our mission of increasing global access to quality education seriously. </p>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="footer-widget "  >
									<div className="footer-menu ul-li-block">
										<h2 className="widget-title">Useful Links</h2>
										<ul>
											<li><span><i className="fas fa-caret-right"></i>About Us</span></li>
											<li><span><i className="fas fa-caret-right"></i>Graphic Design</span></li>
											<li><span><i className="fas fa-caret-right"></i>Mobile Apps</span></li>
											<li><span><i className="fas fa-caret-right"></i>Responsive Website</span></li>
											<li><span><i className="fas fa-caret-right"></i>Graphic Design</span></li>
											<li><span><i className="fas fa-caret-right"></i>Mobile Apps</span></li>
										</ul>
									</div>
								</div>
								<div className="footer-menu ul-li-block "  >
									<h2 className="widget-title">Account Info</h2>
									<ul>
										<li><span><i className="fas fa-caret-right"></i>Setting Account</span></li>
										<li><span><i className="fas fa-caret-right"></i>Login & Register</span></li>
										<li><span><i className="fas fa-caret-right"></i>Contact Us</span></li>
										<li><span><i className="fas fa-caret-right"></i>Graphic Design</span></li>
										<li><span><i className="fas fa-caret-right"></i>Mobile Apps</span></li>
										<li><span><i className="fas fa-caret-right"></i>Responsive Website</span></li>
									</ul>
								</div>
							</div>
							<div className="col-md-3">
								<div className="footer-widget "  >
									<h2 className="widget-title">Photo Gallery</h2>
									<div className="photo-list ul-li">
										<ul>
											<li>
												<img src="assets/img/gallery/g-1.jpg" alt="" />
												<div className="blakish-overlay"></div>
												<div className="pop-up-icon">
													<a href="assets/img/gallery/g-1.jpg" data-lightbox="roadtrip">
														<i className="fas fa-search"></i>
													</span>
												</div>
											</li>
											<li>
												<img src="assets/img/gallery/g-2.jpg" alt="" />
												<div className="blakish-overlay"></div>
												<div className="pop-up-icon">
													<a href="assets/img/gallery/g-2.jpg" data-lightbox="roadtrip">
														<i className="fas fa-search"></i>
													</span>
												</div>
											</li>
											<li>
												<img src="assets/img/gallery/g-3.jpg" alt="" />
												<div className="blakish-overlay"></div>
												<div className="pop-up-icon">
													<a href="assets/img/gallery/g-3.jpg" data-lightbox="roadtrip">	<i className="fas fa-search"></i></span>
												</div>
											</li>
											<li>
												<img src="assets/img/gallery/g-4.jpg" alt="" />
												<div className="blakish-overlay"></div>
												<div className="pop-up-icon">
													<a href="assets/img/gallery/g-4.jpg" data-lightbox="roadtrip">	<i className="fas fa-search"></i></span>
												</div>
											</li>
											<li>
												<img src="assets/img/gallery/g-5.jpg" alt="" />
												<div className="blakish-overlay"></div>
												<div className="pop-up-icon">
													<a href="assets/img/gallery/g-5.jpg" data-lightbox="roadtrip">	<i className="fas fa-search"></i></span>
												</div>
											</li>
											<li>
												<img src="assets/img/gallery/g-6.jpg" alt="" />
												<div className="blakish-overlay"></div>
												<div className="pop-up-icon">
													<a href="assets/img/gallery/g-6.jpg" data-lightbox="roadtrip">	<i className="fas fa-search"></i></span>
												</div>

											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div> 
				
					<div className="footer-social-subscribe mb65">
						<div className="row">
							<div className="col-md-3">
								<div className="footer-social ul-li "  >
									<h2 className="widget-title">Social Network</h2>
									<ul>
										<li><span><i className="fab fa-facebook-f"></i></span></li>
										<li><span><i className="fab fa-twitter"></i></span></li>
										<li><span><i className="fab fa-google-plus-g"></i></span></li>
									</ul>
								</div>
							</div>
							<div className="col-md-9">
								<div className="subscribe-form "  >
									<h2 className="widget-title">Subscribe Newsletter</h2>

									<div className="subs-form relative-position">
										<form action="#" method="post">
											<input className="course" name="course" type="email" placeholder="Email Address." />
											<div className="nws-button text-center  gradient-bg text-uppercase">
												<button type="submit" value="Submit">Subscribe now</button> 
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="copy-right-menu">
						<div className="row">
							<div className="col-md-6">
								<div className="copy-right-text">
									<p>© 2018 - Designed & Developed by <a href="https://jthemes.com" title="Best Premium WordPress, HTML Template Store"> Jthemes Studio</span>. All rights reserved</p>
								</div>
							</div>
							<div className="col-md-6">
								<div className="copy-right-menu-item float-right ul-li">
									<ul>
										<li><span>License</span></li>
										<li><span>Privacy & Policy</span></li>
										<li><span>Term Of Service</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</footer>
    */}
    </div>
  )
}

export default Home
